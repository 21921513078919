import { COLOURS } from '@core-ui/styles';
import { IMenuOption } from '@/components/Menu/Menu';
import MoreButton from '@/components/MoreButton';
import { FileStatusEnum, InboxRecordSchema } from '@/generated';
import { openBackofficeModal } from '@/pages/backoffice/Modals/actions';
import { ImageCarouselModalData } from '@/pages/backoffice/Modals/command';
import { Image, PaperPlaneTilt, Trash } from '@phosphor-icons/react';
import React from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';
import DeleteSingleContractTransactionModalData from '../../../../Modals/command/DeleteSingleContractTransactionModalData';
import SendToXeroItemModalData from '../../../../Modals/command/SendToXeroItemModalData';

export interface IProps {
  item: InboxRecordSchema;
}

const SingleContractRowMoreButton = (props: IProps) => {
  const { item } = props;

  const dispatch = useDispatch();

  const isSendToErpActive = !item.is_sent_xero && item.status === FileStatusEnum.DIGITIZED;

  const handleOpenSourceFileModal = () => {
    const isFilePdf = item.filename?.endsWith('.pdf');
    const previewFiles = isFilePdf ? item.previews?.map((preview) => preview.link) : [item.link];

    if (previewFiles) {
      dispatch(
        openBackofficeModal(
          new ImageCarouselModalData(previewFiles, item.document_id, item.status, item.uploaded_by_id, item.created_at)
        )
      );
    }
  };

  const handleDelete = () => {
    // TODO: we are not sure about UNKNOWN status, need to check which endpoint should be used for this type of file

    // only already digitized files have document_id. We use /files/{file_id} route for deleting
    // not yet digitized and returned files and /document/{document_id} route for deleting already digitized
    const id = item.status === (FileStatusEnum.DIGITIZED || FileStatusEnum.UNKNOWN) ? item.document_id : item.id;

    if (id) {
      dispatch(openBackofficeModal(new DeleteSingleContractTransactionModalData(id, item.status)));
    }
  };

  // TODO: тут не должен отправляться параметр contract_id? его нет на бэке
  const handleOpenSendToErpModal = () => {
    const invoiceCode = item.erp_category ?? undefined;

    dispatch(openBackofficeModal(new SendToXeroItemModalData(invoiceCode, item.id)));
  };

  const options: IMenuOption[] = [
    {
      title: <FormattedMessage id="label.open_source_file" />,
      dataTestId: 'contract-transaction-more-button-open-source-file',
      icon: <Image />,
      onClick: handleOpenSourceFileModal,
      visible: true,
    },
    {
      title: <FormattedMessage id="label.send_to_erp" />,
      icon: <PaperPlaneTilt />,
      onClick: handleOpenSendToErpModal,
      visible: isSendToErpActive,
    },
    {
      title: <FormattedMessage id="label.delete" />,
      dataTestId: 'contract-transaction-more-button-delete',
      textColor: COLOURS.Strawberry.Solid[600],
      icon: <Trash color={COLOURS.Strawberry.Solid[600]} />,
      onClick: handleDelete,
      visible: true,
    },
  ];

  return <MoreButton options={options} isRounded buttonVariant="filled" dataTestId="single-contract-row-more-button" />;
};

export default SingleContractRowMoreButton;
