import { Nullable } from '@core-ui/types';
import { buildURL } from '@core-ui/url';
import { DELETE, GET } from '@/api/oceanApi';
import { ROUTES } from '@/app/consts/routes';
import { responseError } from '@/app/sagas';
import { getCurrentBoatId } from '@/app/selectors';
import { showNotification } from 'app/actions';
import { ContractOutputSchema, OrderContractsFields } from '@/generated';
import history from '@/history';
import { BACKOFFICE_TABS } from '@/pages/backoffice/consts';
import { IContractsFiltersState } from '@/pages/backoffice/Contracts/ContractsFilters/reducer';
import { getContractsFilters } from '@/pages/backoffice/Contracts/ContractsFilters/selector';
import { getPaginationPage, getSortAndOrder } from '@/pages/backoffice/Contracts/ContractsList/selector';
import { closeBackofficeModal, toggleModalLoading } from '@/pages/backoffice/Modals/actions';
import { Action } from 'redux-actions';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

const LIMIT = 50;

interface ISortAndOrderDesc {
  orderBy: Nullable<OrderContractsFields>;
  isSortDesc: Nullable<boolean>;
}

interface IFetchContracts {
  page: number;
}

function* fetchContracts({ page }: IFetchContracts) {
  const filters: IContractsFiltersState = yield select(getContractsFilters);
  const boatId: number = yield select(getCurrentBoatId);

  const { orderBy, isSortDesc }: ISortAndOrderDesc = yield select(getSortAndOrder);

  const requestUrl = buildURL(
    '/contracts',
    {
      page,
      per_page: LIMIT,
      order_by: orderBy || undefined,
      is_desc: isSortDesc,
      boat_id: boatId,
      name: filters.name || undefined,
      vendors_id: filters.vendorsId || undefined,
    },
    {
      arrayFormat: 'none',
    }
  );

  const contracts: ContractOutputSchema[] = yield call(GET, requestUrl);

  return contracts;
}

function* getContractsList() {
  try {
    const contracts: ContractOutputSchema[] = yield fetchContracts({ page: 0 });

    yield all([
      put(actions.setPaginationPage(0)),
      put(
        actions.setContractsList({
          value: contracts,
          hasData: Boolean(contracts.length),
          isAllDataReceived: contracts.length < LIMIT,
        })
      ),
    ]);
  } catch (e) {
    yield call(responseError, e);
    yield put(
      actions.setContractsList({
        error: e as Error,
        hasData: false,
        isAllDataReceived: false,
      })
    );
  }
}

function* getChunkContractsList() {
  try {
    const currentPage: number = yield select(getPaginationPage);
    const newContracts: ContractOutputSchema[] = yield fetchContracts({ page: currentPage + 1 });

    yield all([
      put(actions.setPaginationPage(currentPage + 1)),
      put(
        actions.setChunkContractsList({
          value: newContracts,
          isAllDataReceived: newContracts.length < LIMIT,
        })
      ),
    ]);
  } catch (e) {
    yield call(responseError, e);
  }
}

function* deleteContract({ payload }: Action<actions.IDeleteContract>) {
  try {
    yield put(toggleModalLoading());

    yield call(DELETE, `/contracts/${payload.id}`);

    yield put(
      showNotification({
        variant: 'success',
        title: 'contracts.delete.success',
      })
    );

    if (payload.shouldRedirect) {
      const boatId: number = yield select(getCurrentBoatId);

      history.push(buildURL(`/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.CONTRACTS}`));
    } else {
      yield put(actions.getContractsList(null));
    }
  } catch (e) {
    yield put(
      showNotification({
        variant: 'error',
        title: 'contracts.delete.error',
      })
    );
  } finally {
    yield put(closeBackofficeModal());
  }
}

export default [
  takeLatest(actions.getContractsList, getContractsList),
  takeLatest(actions.getChunkContractsList, getChunkContractsList),
  takeLatest(actions.deleteContract, deleteContract),
];
