import { Nullable, SORT_ORDER_ENUM } from '@core-ui/types';
import { buildURL } from '@core-ui/url';
import { GET } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { getCurrentBoatId } from '@/app/selectors';
import { InboxRecordSchema, myocean__routes__inbox__inbox_list__OrderFields } from '@/generated';
import { IInboxFiltersState } from '@/pages/backoffice/Inbox/InboxFilters/reducer';
import { getInboxFilters } from '@/pages/backoffice/Inbox/InboxFilters/selector';
import { getPaginationPage, getSortAndOrder } from '@/pages/backoffice/Inbox/InboxList/selector';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

const LIMIT = 50;

interface ISortAndOrderDesc {
  orderBy: Nullable<myocean__routes__inbox__inbox_list__OrderFields>;
  order: SORT_ORDER_ENUM;
}

interface IFetchInboxItems {
  page: number;
}

function* fetchInboxItems({ page }: IFetchInboxItems) {
  const filters: IInboxFiltersState = yield select(getInboxFilters);
  const boatId: number = yield select(getCurrentBoatId);

  const { orderBy, order }: ISortAndOrderDesc = yield select(getSortAndOrder);

  const requestUrl = buildURL(
    `/inbox/${boatId}/list`,
    {
      page,
      per_page: LIMIT,
      order_by: orderBy || undefined,
      is_desc: order === SORT_ORDER_ENUM.DESC,
      date_from: filters.dateFrom ? Number(filters.dateFrom) : undefined,
      date_to: filters.dateTo ? Number(filters.dateTo) : undefined,
      uploaded_by: filters.uploadedBy ? filters.uploadedBy : undefined,
      filter_statuses: filters.status ? filters.status : undefined,
    },
    {
      arrayFormat: 'none',
    }
  );

  const inboxItems: InboxRecordSchema[] = yield call(GET, requestUrl);

  return inboxItems;
}

function* getInboxList() {
  try {
    const documents: InboxRecordSchema[] = yield fetchInboxItems({ page: 0 });

    yield put(actions.setPaginationPage(0));
    yield put(
      actions.setInboxList({
        value: documents,
        hasData: Boolean(documents.length),
        isAllDataReceived: documents.length < LIMIT,
      })
    );
  } catch (e) {
    yield call(responseError, e);
    yield put(actions.setInboxList({ error: e as Error, hasData: false, isAllDataReceived: false }));
  }
}

function* getChunkInboxList() {
  try {
    const page: number = yield select(getPaginationPage);
    const documents: InboxRecordSchema[] = yield fetchInboxItems({ page: page + 1 });

    yield put(actions.setPaginationPage(page + 1));
    yield put(
      actions.setChunkInboxList({
        value: documents,
        isAllDataReceived: documents.length < LIMIT,
      })
    );
  } catch (e) {
    yield call(responseError, e);
  }
}

export default [
  takeLatest(actions.getInboxList, getInboxList),
  takeLatest(actions.getChunkInboxList, getChunkInboxList),
];
