import { DELETE, PATCH } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { exhaustiveGuard } from '@/app/utils/app';
import { showNotification } from 'app/actions';
import { DocPatchInputSchema, FileStatusEnum } from '@/generated';
import { getSingleContract } from '@/pages/backoffice/Contracts/SingleContractRoot/actions';
import {
  getSingleContractTransactions,
  setSingleContractTransactionsListLoading,
} from '@/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsList/actions';
import { closeBackofficeModal, toggleModalLoading } from '@/pages/backoffice/Modals/actions';
import { Action } from 'redux-actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* updateErpCategory({ payload }: Action<actions.IUpdateErpCategory>) {
  try {
    yield put(setSingleContractTransactionsListLoading({ loading: true }));

    yield call(PATCH, `/document/${payload.documentId}`, {
      xero_category: payload.categoryId,
    } as DocPatchInputSchema);

    yield all([
      put(getSingleContractTransactions(null)),
      put(
        showNotification({
          variant: 'success',
          title: 'notification.success.text.erp_update',
        })
      ),
    ]);
  } catch (e) {
    yield all([
      put(setSingleContractTransactionsListLoading({ loading: false })),
      put(
        showNotification({
          variant: 'error',
          title: 'notification.error.text.erp_update',
        })
      ),
    ]);
  }
}

function* deleteSingleContractDocument({ payload }: Action<actions.IDeleteSingleContractDocument>) {
  try {
    yield put(toggleModalLoading());

    // TODO: we are not sure about UNKNOWN status, need to check which endpoint should be used for this type of file
    switch (payload.status) {
      case FileStatusEnum.DIGITIZED:
      case FileStatusEnum.UNKNOWN:
        yield call(DELETE, `/document/${payload.id}`);
        break;
      case FileStatusEnum.RETURNED:
      case FileStatusEnum.BEING_DIGITIZED:
        yield call(DELETE, `/files/${payload.id}`);
        break;
      default:
        exhaustiveGuard(payload.status);
    }

    yield all([
      put(getSingleContract(null)),
      put(getSingleContractTransactions(null)),
      put(closeBackofficeModal()),
      put(
        showNotification({
          variant: 'success',
          title: 'notification.success.text.single_contract_transaction_delete',
        })
      ),
    ]);
  } catch (e) {
    yield all([call(responseError, e), put(toggleModalLoading())]);
  }
}

export default [
  takeLatest(actions.updateErpCategory, updateErpCategory),
  takeLatest(actions.deleteSingleContractDocument, deleteSingleContractDocument),
];
