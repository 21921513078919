import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { generateEmptyArray } from '@/app/utils/app';
import React from 'react';
import { useIntl } from 'components/Intl';

const SingleContractInfoSkeleton = () => {
  const intl = useIntl();

  return (
    <Grid
      container
      direction="column"
      gap={4}
      maxWidth={350}
      role="progressbar"
      aria-label={intl.formatMessage({ id: 'aria.label.loading' })}
    >
      {generateEmptyArray(9).map((_, index) => (
        <Skeleton key={index} variant="rounded" height={20} />
      ))}
    </Grid>
  );
};

export default SingleContractInfoSkeleton;
