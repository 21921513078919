import { getCurrentBoatId } from '@/app/selectors';
import { getAmountFormatterWithCurrencyCodeById } from '@/dictionary/selector';
import { getSingleContract } from '@/pages/backoffice/Contracts/SingleContractRoot/selector';
import { createSelector } from 'reselect';

export default createSelector(
  [getSingleContract, getCurrentBoatId, getAmountFormatterWithCurrencyCodeById],
  (transaction, boatId, currencyFormatter) => ({
    item: transaction.value ?? null,
    boatId,
    currencyFormatter,
  })
);
